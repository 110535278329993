/* You can add global styles to this file, and also import other style files */

$level-status-draft-color: #eb5933;
$level-status-testing-color: #eba133;
$level-status-published-color: #00c884;

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

fieldset {
  border: 0;
  padding: 0;
}

.mat-form-field {
  width: 100%;
}

.mat-dialog-container > ng-component {
  height: 100%;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.mat-list-item {
  display: flex !important;
  height: unset !important;
  min-height: 48px;
}

.mat-list-item-content {
  flex: 1 1 0;
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.bold {
  font-weight: bold;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
