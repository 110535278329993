@use "~@angular/material" as mat;
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$tenjin-blue: (
  50: #e0f2ff,
  100: #b3dffe,
  200: #80c9fe,
  300: #4db3fd,
  400: #26a3fc,
  500: #0093fc,
  600: #008bfc,
  700: #0080fb,
  800: #0076fb,
  900: #0064fa,
  A100: #ffffff,
  A200: #edf3ff,
  A400: #bad1ff,
  A700: #a1c0ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$app-primary: mat.define-palette($tenjin-blue);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$app-theme: mat.define-dark-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

$app-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

@font-face {
  font-family: "Azo Sans";
  src: url("./assets/fonts/azo-sans-web-normal.eot");
  src: url("./assets/fonts/azo-sans-web-normal.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/azo-sans-web-normal.woff2") format("woff2"),
    url("./assets/fonts/azo-sans-web-normal.woff") format("woff"),
    url("./assets/fonts/azo-sans-web-normal.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Azo Sans Bold";
  src: url("./assets/fonts/azo-sans-web-bold.eot");
  src: url("./assets/fonts/azo-sans-web-bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/azo-sans-web-bold.woff2") format("woff2"),
    url("./assets/fonts/azo-sans-web-bold.woff") format("woff"),
    url("./assets/fonts/azo-sans-web-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

$custom-typography: mat.define-typography-config(
  $font-family: "Azo Sans, sans-serif",
);

@include mat.core($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

.light-theme {
  @include mat.all-component-colors($app-light-theme);
}

body {
  font-family: azo-sans-web, sans-serif;
}

.mat-expansion-panel,
.mat-app-background {
  background-color: #303134;
}

.mat-list-item.dark-button,
.mat-button.dark-button {
  background-color: #28292b;
}

.mat-action-list .mat-list-item:not(.dark-button) {
  background-color: #4e5053;
}

.mat-action-list .mat-list-item.selected:not(.dark-button) {
  background-color: white;
  color: black;
}

.mat-card,
.mat-dialog-container {
  background-color: #4e5053;
}

.mat-card {
  border-radius: 8px;
}

.mat-dialog-container {
  border-radius: 6px !important;
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}

.mat-tab-body-wrapper {
  margin-top: 20px;
}

.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

$editor-background-color: #28282a;

.ql-toolbar.ql-snow {
  background-color: $editor-background-color;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: none;

  .ql-stroke {
    stroke: white;
  }

  .ql-fill {
    fill: white;
  }

  .ql-picker {
    color: white;
  }

  .ql-picker-options {
    background-color: $editor-background-color;
  }
}

.ql-container.ql-snow {
  background-color: $editor-background-color;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
}

// Placeholder text
.ql-editor.ql-blank::before {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.3;
}
